.typing-effect__typewriter__typing_step span {
  overflow: hidden;
  margin: 0 0;
  padding: 0;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes stop-blink {
  from,
  to {
    border-right: transparent;
  }
}
